import React, { useState, useEffect, useContext, memo } from "react";
import config from "../config.js";
import { DataT, ProfileT } from "../types.js";
import { View, ImageBackground, TouchableOpacity, FlatList, Image, Text, TextInput, KeyboardAvoidingView, Button, Platform, SafeAreaView, Keyboard } from "react-native";
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import { Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import CardStack, { Card } from "react-native-card-stack-swiper";
import { City, Filters, CardItem, PhotoSlider, Icon, ChatBubble, ProfileView, GradientButton } from "./";
import styles, { DIMENSION_WIDTH, PRIMARY_COLOR, TEXT_WHITE, TEXT_WHITE_60, WHITE, SECONDARY_COLOR, GRAY, DARK_GRAY } from "../assets/styles";
import * as RootNavigation from '../RootNavigation';
import { get, range, map, noop, floor, round, clamp, concat, find, reverse, cloneDeep, replace, slice, debounce } from 'lodash';
import { AppContext, messageProfile, profileAction } from '../context/app-context';
import ProfileModal from "../modals/ProfileModal"

const Conversation = ({ route, navigation }) => {
  const { id } = route.params;
  const [state, dispatch] = useContext(AppContext);

  console.log('convo!')

  useEffect(() => {
    (async () => {
      console.log('convo mounted!', route)
    })();
    return function cleanup() {
      console.log('cleanup copnvo!')
      navigation.setParams({ id: 'asdf' })
      
    }
  }, []);

  const maxLength = 500

  const profile = find(state.matches, {id: id})

  const mainScrollRef = React.useRef({});
  const inputRef = React.useRef({});
  const [messageDraft, setMessageDraft] = useState('');
  const [history, setHistory] = useState(50)

  //const messages = Platform.OS === 'web' ? reverse(cloneDeep(profile?.messages)) : profile?.messages
  const messages = slice(profile?.messages, 0, history)
  const image = get(profile, `photos[0]`, '')

  const showProfileModal = (profile) => {
    Keyboard.dismiss()
    ProfileModal.showContext(
      <View style={{width: vw(100), height: vh(100), maxWidth: vh(60)}}>
        <ProfileView profile={profile} width={vw(100)} height={vh(100)}>
          {getActions(profile)}
        </ProfileView>
        <TouchableOpacity style={[{position: 'absolute'}, styles.topIconLeft, {top: 50}]} onPress={() => {hideProfileModal()}}>
          <Icon
            name="chevron-back"
            size={40}
            color={WHITE}
          />
        </TouchableOpacity>
      </View>
    )
  }

  const hideProfileModal = () => {
    ProfileModal.hideContext()
  }

  const doAction = async (action: string, profile) => {
    await profileAction(dispatch, action, profile?.id)
    hideProfileModal()
    RootNavigation.navigate('list')
}

  const getActions = (profile) => {
    return (
    <View pointerEvents={'box-none'} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', zIndex: 2, width: '100%', height: '100%', paddingBottom: 20 }}>
      <GradientButton
        style={{ marginVertical: 0, marginHorizontal: 20 }}
        gradientBegin={GRAY}
        gradientEnd={DARK_GRAY}
        gradientDirection="diagonal"
        height={60}
        width={60}
        impact
        impactStyle='Light'
        onPressAction={() => doAction('dislike', profile)}
      ><Ionicons name="heart-dislike-sharp" size={40} color={TEXT_WHITE} /></GradientButton>
    </View>
    )
  }

  const sendMessage = async () => {
    if(!isValidMessage()) return
    const messageToSend = messageDraft
    setMessageDraft('')
    inputRef.current.clear()
    await messageProfile(dispatch, messageToSend, profile?.id)
    scrollToBottom()
  }

  const isValidMessage = () => {
    const plainMessage = replace(messageDraft, /\s/g, '')
    return plainMessage.length > 0
  }

  const scrollToBottom = () => {
    /* if(Platform.OS === 'web') {
      mainScrollRef?.current?.scrollToEnd({animated: false})
      return
    } */
    mainScrollRef?.current?.scrollToOffset({animated: false, offset: 0})
  };

  const getItemLayout = (data, index) => {
    const numLines = 20
   return {length: numLines*20 + 2*10, offset: (numLines*20 + 2*10) * index, index}
  }

  const data = [];
	for (let i = 0; i < messages.length; i++) {
		data.push({
			key: i,
			text: messages[i].message,
      right: messages[i]?.to_profile_id === profile?.id,
      image: messages[i]?.to_profile_id === profile?.id ? undefined : image,
		});
	}
  const onEndReached = () => {
    console.log('on end')
    history < 500 ? setHistory(history + 5) : noop()
  }

  return (
    <SafeAreaView pointerEvents={'box-none'} style={[styles.container, styles.horizontal, styles.darkBackground]}>
      <TouchableOpacity style={[{position: 'absolute'}, styles.topIconLeft, {top: Platform.OS === 'ios' ? 80 : 40}]} onPress={() => { RootNavigation.navigate('list')  }}>
          <Icon
            name="chevron-back"
            size={40}
            color={WHITE}
          />
        </TouchableOpacity>
      <KeyboardAvoidingView style={[styles.bg, styles.containerHome, styles.horizontal, {flex: 1}]} behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
        
        <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
          <TouchableOpacity
            style={[{}]}
            onPress={() => {showProfileModal(profile)}}
          >
            <Image
                source={typeof image == "string" ? {uri: `${config.IMAGES_URL}/${image}`}: image}
                style={{borderRadius: 90, width: 80, height: 80, resizeMode: 'cover'}}
            />
          </TouchableOpacity>
          <Text style={[styles.title, {marginBottom: 0, textAlign: 'center'}]}>{`${profile?.first_name}`}</Text>
        </View>
        <FlatList
          style={{flex: 1, scaleY: -1}}
          contentContainerStyle={{flexDirection: 'column', width: '100%'}}
          numColumns={1}
          ref={mainScrollRef}
          inverted={Platform.OS === 'ios' || Platform.OS === 'web'}
          data={data}
			    renderItem={({ item }) => <ChatBubble text={item.text} right={item.right} image={item.image}/>}
          removeClippedSubviews={true}
          showsVerticalScrollIndicator={true}
          showsHorizontalScrollIndicator={false}
          onEndReached={onEndReached}
          onEndReachedThreshold={0.5}
          disableVirtualization={Platform.OS === 'web'}
          /* onContentSizeChange={() => scrollToBottom()} */
          /* onLayout={onLayout} */
        />
        <View style={[
              {paddingLeft: 20, width: '100%', marginBottom: 10, marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}
            ]}>
          <TextInput
            keyboardType={'default'}
            ref={inputRef}
            multiline={true}
            style={[styles.input, styles.messageInput]}
            placeholder="Send a message"
            placeholderTextColor={styles.smallText.color}
            onChangeText={setMessageDraft}
            maxLength={maxLength}
            defaultValue={''}
            onSubmitEditing={() => sendMessage()}
            blurOnSubmit={true}
          />
          <TouchableOpacity onPress={() => sendMessage()} disabled={!isValidMessage()}>
            <Ionicons name="send-sharp" size={25} style={{color: isValidMessage() ? SECONDARY_COLOR : TEXT_WHITE_60, textAlign: 'center', paddingHorizontal: 20}} />
          </TouchableOpacity>
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default Conversation;
