import React, { useEffect, useState, useRef, useContext  } from "react";
import config from "../config.js";
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Image,
  FlatList,
  Button,
  TouchableWithoutFeedback,
  SafeAreaView,
  Animated
} from "react-native";
import { TapGestureHandler, PanGestureHandler } from 'react-native-gesture-handler'
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import Modal from "react-native-modal";
import GradientButton from "./GradientButton";
import DEMO from "../assets/data/demo";
import { Entypo, Feather, FontAwesome, FontAwesome5, Foundation, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Icon from "../icons/Icons.js";
import { AppContext, uploadPhoto, removePhoto, profileAction, fetchProfiles, fetchMatches, incrementProfileIndex} from "../context/app-context";
import { LinearGradient } from 'expo-linear-gradient';
import styles, { DARK_GRAY, GRAY, DIMENSION_HEIGHT, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, WHITE, TEXT_WHITE, BLACK, PRIMARY_RED, TEXT_WHITE_60 } from "../assets/styles";
import { createStackNavigator } from "@react-navigation/stack";
import { get, isEmpty, map, noop, floor, round, clamp, last, invoke, min, has, capitalize } from 'lodash';
import { BlurView } from 'expo-blur';
import ReportView from './ReportView'
import {attributeLabels} from './AttributeEditor'
import ConfirmationModal from "../modals/ConfirmationModal"
import * as RootNavigation from '../RootNavigation';

const ProfileView = ({profile, width, height, children}) => {
    if(isEmpty(profile)) return null
    const [state, dispatch] = useContext(AppContext)
    const photos = isEmpty(profile?.photos) ? [] : profile?.photos
    const [active, setActive] = useState(0);
    const [fadeColor, setFadeColor] = useState(SECONDARY_COLOR);
    const [fadeAnimation, setFadeAnimation] = useState(new Animated.Value(0));

    const image = get(photos, '[0]', '')

    const photoScrollRef = React.useRef({});
    const mainScrollRef = React.useRef({});
    const clickRef = React.useRef()

    const maxWidth = vh(60)
    const actualWidth = min([width, maxWidth])

    const fadeIn = async (cb: any) => {
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 150,
        useNativeDriver: true
      }).start(cb);
    };
  
    const fadeOut = async (cb?: any) => {
      Animated.timing(fadeAnimation, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true
      }).start(cb);
    };    

    const change = ({nativeEvent}: any) => {
      const slide = round(
        nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width,
      );
      clamp(slide, 0, photos.length);
      if (slide !== active) {
        setActive(slide);
      }
    };

    const scrollPhoto = (nativeEvent) => {
      const x = nativeEvent?.locationX ? nativeEvent.locationX : nativeEvent?.x
      const index = x > vw(100) / 2.0 ? 1 : -1
      invoke(photoScrollRef, 'current.scrollTo', { x: (active + index) * actualWidth, y: 0, animated: false })
    }

    const getLocationString = () => {
      if(!profile) return ''
      if(!profile.city && !profile.state) return ''
      if(profile.city && !profile.state) return profile.city
      return `${profile.city}, ${profile.state}`
    }

    const getBusinessPositionString = () => {
      if(!profile) return ''
      if(!profile.title && !profile.company) return ''
      if(profile.title && !profile.company) return profile.title
      if(profile.company && !profile.title) return profile.company
      return `${profile.title} @ ${profile.company}`
    }

    const getTempleString = (temple_status) => {
      if(temple_status === 'no') return 'No Temple Recommend'
      return `Temple ${getAttributeString(temple_status)}`
    }

    const getAttributeString = (attribute) => {
      return has(attributeLabels, attribute) ? attributeLabels[attribute] : capitalize(attribute)
    }

    const showBlockModal = () => {
      ConfirmationModal.showContext(
          <View style={[styles.darkBackground, { width: 300, padding: 20, borderRadius: 8, alignItems: 'center', justifyContent: 'flex-start', marginBottom: 200}]}>
            <SafeAreaView pointerEvents={'box-none'} style={{width: '100%', alignItems: 'center'}}>
              <Text style={[styles.reportStyle, { fontSize: 20, color: TEXT_WHITE_60, textAlign: 'center' }]}>{`Block ${profile.first_name} Permanently?`}</Text>
              <View style={{width: '50%',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20}}>
                <TouchableOpacity onPress={() => hideModal()} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
                  <Text style={styles.saveButtonText}>{'CANCEL'}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {next('block');hideModal()}} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
                  <Text style={styles.saveButtonText}>{'BLOCK'}</Text>
                </TouchableOpacity>
              </View>
            </SafeAreaView>
          </View>
      )
    }

    const showReportModal = () => {
      ConfirmationModal.showContext(
          <View style={[styles.darkBackground, { width: 300, padding: 20, borderRadius: 8, alignItems: 'center', justifyContent: 'flex-start', marginBottom: 200}]}>
            <SafeAreaView pointerEvents={'box-none'} style={{width: '100%', alignItems: 'center'}}>
              <ReportView onConfirm={(report, reportDetails) => {next('report', {[report]: reportDetails});hideModal()}} onCancel={hideModal} message={`Report and Block ${profile.first_name} Permanently?`} ref={clickRef}/>
            </SafeAreaView>
          </View>
      )
    }

    const hideModal = () => {
      ConfirmationModal.hideContext()
    }

    const report = async () => {
      showBlockModal()
    }

    const next = (action: string, details?: any) => {
      action == 'like' ? setFadeColor(SECONDARY_COLOR) : setFadeColor(DARK_GRAY)
      fadeIn(async () => {
        // @ts-ignore: Object is possibly 'null'.
        invoke(photoScrollRef, 'current.scrollTo', { x: 0, y: 0, animated: false })
        invoke(mainScrollRef, 'current.scrollTo', { x: 0, y: 0, animated: false })
        await profileAction(dispatch, action, profile.id, details)
        if(state.profile_index % 18 == 0) await fetchProfiles(dispatch)
        await incrementProfileIndex(dispatch)
        fadeOut()
      })
    }

    return (
      <View pointerEvents={'box-none'} style={[{flex: 1, borderRadius: 8, overflow: 'hidden'}, styles.container, styles.horizontal]}>
        {fadeAnimation && <Animated.View pointerEvents={'box-none'}
        style={{ zIndex: 3, opacity: fadeAnimation, width: '100%', height: '100%', backgroundColor: fadeColor, position: 'absolute' }}/>}
        <View style={{backgroundColor: 'rgba(54, 53, 84, 1)', flex: 1,  borderRadius: 8, marginTop: 0, overflow: 'hidden', width: '100%', maxWidth: maxWidth}}>
        <ScrollView
          nestedScrollEnabled={true}
          directionalLockEnabled={true}
          showsVerticalScrollIndicator={true}
          ref={mainScrollRef}
          bounces={false}
          alwaysBounceVertical={false}
          bouncesZoom={false}
          contentContainerStyle={{alignItems: 'center'}}
        >
            <View style={{width: width, height: height,  maxWidth: maxWidth}}>   
              <ScrollView
                keyboardShouldPersistTaps={'always'}
                nestedScrollEnabled={true}
                disableIntervalMomentum={true}
                pagingEnabled
                horizontal
                ref={photoScrollRef}
                onScroll={change}
                scrollEventThrottle={16}
                showsHorizontalScrollIndicator={false}
                onStartShouldSetResponder={() => true}
                >
                  {photos.map((image, index) => (
                    <TouchableWithoutFeedback key={index} onPress={(evt) => {scrollPhoto(evt.nativeEvent)}}>
                      <Image
                        source={typeof image == "string" ? {uri: `${config.IMAGES_URL}/${image}`}: image}
                        style={{width: width, height: height, maxWidth: maxWidth, resizeMode: 'cover'}}
                      />
                    </TouchableWithoutFeedback>
                  ))}
              </ScrollView>
              
              <View style={styles.pagination}>
                {photos.length > 1 && photos.map((i, k) => (
                  <Text key={k} style={[ styles.dot, k == active ? styles.activeDot : {}]}>
                    •
                  </Text>
                ))}
              </View>

            </View>    
          <LinearGradient pointerEvents={'none'} style={{position: 'absolute', top: 0, height: height, width: '100%'}} colors={['rgba(54, 53, 84, 0)', 'rgba(54, 53, 84, 1)']} locations={[0.6, 0.98]} />        
            <View style={{ zIndex: 3, elevation: 3, flex: 1, paddingHorizontal: 0, marginTop: vh(-30), marginBottom: vh(15), width: '100%'}}>  
              <View style={[styles.cardInfo]}>
                <View style={{marginBottom: 0, flex: 1, flexDirection: 'column', maxWidth: width}}>
                  <Text style={styles.nameStyle}>{profile.first_name}<Text style={styles.ageStyle}>{`  ${profile.age}`}</Text></Text>
                  <Text>
                    {profile.distance && (<Text style={styles.attributesTextStyle}><Ionicons name="location-sharp" size={20} color={TEXT_WHITE} title="location"/>{`  ${profile.distance}${isEmpty(getLocationString()) ? '' : ' - '}${getLocationString()}`}</Text>)}
                    {profile.height && (<Text style={styles.attributesTextStyle}>{`    |    `}<Entypo name="ruler" size={20} color={TEXT_WHITE} title="height"/>{`  ${floor(profile.height/12)}'${profile.height%12}"`}</Text>)}
                    {(profile.title || profile.company) && (<Text style={styles.attributesTextStyle}>{`    |    `}<FontAwesome name="briefcase" size={20} color={TEXT_WHITE} title="job title and company"/>{`  ${getBusinessPositionString()}`}</Text>)}
                    {profile.school && (<Text style={styles.attributesTextStyle}>{`    |    `}<Ionicons name="school" size={20} color={TEXT_WHITE} title="school"/>{`  ${profile.school}`}</Text>)}
                    {profile.school && (<Text style={styles.attributesTextStyle}>{`    |    `}<Ionicons name="school" size={20} color={TEXT_WHITE} title="school"/>{`  ${getAttributeString(profile.education)}`}</Text>)}
                    {profile.hometown && (<Text style={styles.attributesTextStyle}>{`    |    `}<Ionicons name="home" size={20} color={TEXT_WHITE} title="hometown"/>{`  ${profile.hometown}`}</Text>)}
                    {profile.mission && (<Text style={styles.attributesTextStyle}>{`    |    `}<FontAwesome name="plane" size={20} color={TEXT_WHITE} title="mission"/>{`  ${profile.mission}`}</Text>)}
                    {profile.activity_level && (<Text style={styles.attributesTextStyle}>{`    |    `}<Icon name="church" size={20} color={TEXT_WHITE} title="church attendance"/>{` Attends Church ${getAttributeString(profile.activity_level)}`}</Text>)}
                    {profile.marriage_status && (<Text style={styles.attributesTextStyle}>{`    |    `}<MaterialCommunityIcons name="ring" size={20} color={TEXT_WHITE} title="marriage_status"/>{`  ${getAttributeString(profile.marriage_status)}`}</Text>)}
                    {profile.current_kids && (<Text style={styles.attributesTextStyle}>{`    |    `}<FontAwesome5 name="baby" size={20} color={TEXT_WHITE} title="current_kids"/>{`  ${getAttributeString(profile.current_kids)}`}</Text>)}
                    {profile.future_kids && (<Text style={styles.attributesTextStyle}>{`    |    `}<FontAwesome5 name="baby" size={20} color={TEXT_WHITE} title="future_kids"/>{`  ${getAttributeString(profile.future_kids)}`}</Text>)}
                    {profile.politics && (<Text style={styles.attributesTextStyle}>{`    |    `}<Icon name="politics" size={20} color={TEXT_WHITE} title="politics"/>{`  ${getAttributeString(profile.politics)}`}</Text>)}
                    {profile.temple_status && (<Text style={styles.attributesTextStyle}>{`    |    `}<Icon name="temple" size={20} color={TEXT_WHITE} title="temple recommend"/>{`  ${getTempleString(profile.temple_status)}`}</Text>)}
                    {profile.pets && (<Text style={styles.attributesTextStyle}>{`    |    `}<MaterialIcons name="pets" size={20} color={TEXT_WHITE} title="pets"/>{`  ${getAttributeString(profile.pets)}`}</Text>)}
                  </Text>
                  {map(profile?.about, (prompt, index) => !isEmpty(prompt?.answer) ?
                    <View key={index} style={{width: '100%', marginTop: 15}}>
                      <Text style={[styles.promptText]}>{prompt?.prompt}</Text>
                      <Text style={[styles.attributesTextStyle]}>{prompt?.answer}</Text>
                    </View> : null
                  )}
                    
                </View>
              </View>
              <View style={{marginTop: 150}}>
                <TouchableOpacity
                  style={{ marginVertical: 8, zIndex: 0, marginBottom: 40 }}
                  hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}
                  onPress={() => showBlockModal()}
                >
                  <Text style={[styles.reportStyle, { fontSize: 20, color: TEXT_WHITE_60, textAlign: 'center', textTransform: 'uppercase' }]}>{`BLOCK ${profile.first_name}`}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ marginVertical: 8, zIndex: 0 }}
                  hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}
                  onPress={() => showReportModal()}
                >
                  <Text style={[styles.reportStyle, { fontSize: 20, color: PRIMARY_RED, textAlign: 'center', textTransform: 'uppercase' }]}>{`REPORT ${profile.first_name}`}</Text>
                </TouchableOpacity>
              </View>
            </View>
        </ScrollView>
        </View>
      
        {children}

      </View>
    )
  }


  export default ProfileView