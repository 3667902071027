import React, { useEffect, useState, useRef, useContext, useImperativeHandle } from "react"
import config from "../config.js"
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform, ScrollView, SafeAreaView, KeyboardAvoidingView, Keyboard } from "react-native"
import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, updateUserProfile, uploadPhoto, removePhoto } from "../context/app-context"
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, WHITE, TEXT_WHITE_60, TEXT_WHITE_40 } from "../assets/styles"
import { get, isEmpty, range, map, isNumber, forEach, noop, assign, union, set, capitalize, floor, has, startCase, filter, find, concat } from 'lodash'
import { AntDesign, Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import DropDownPicker from 'react-native-dropdown-picker'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import RadioGroup, {RadioButtonProps} from 'react-native-radio-buttons-group'
import PhotoPicker from './PhotoPicker'
import Icon from './Icon'
import * as RootNavigation from '../RootNavigation'
import { validatePathConfig } from "@react-navigation/native"
import ConfirmationModal from "../modals/ConfirmationModal"
import { prompts } from "../assets/prompts"

export const attributeOptions = {
  activity_level: ['regularly', 'often', 'occasionally', 'rarely', 'never'],
  marriage_status: ['never_married', 'widowed', 'divorced'],
  current_kids: ['kids', 'no_kids'],
  future_kids: ['want_kids', 'dont_want_kids'],
  education: ['high_school', 'some_college', 'bachelors_degree', 'postgraduate'],
  politics: ['conservative', 'liberal', 'moderate', 'other'],
  temple_status: ['recommend', 'worthy', 'no'],
  pets: ['dogs', 'cats', 'birds', 'other'],
}

export const attributeLabels = {
  activity_level: 'Church Attendance',
  marriage_status: 'Marital Status',
  current_kids: 'Children',
  future_kids: 'Family Plans',
  temple_status: 'Temple Recommend',

  kids: 'Have Children',
  no_kids: `Don't Have Children`,

  want_kids: `Don't Want Children`,
  dont_want_kids: `Want Children`,

  never_married: 'Never Married',

  high_school: 'High School',
  some_college: 'Some College',
  bachelors_degree: `Bachelor's Degree`,
}

const AttributeEditor = React.forwardRef((props, ref) => {

  const [attributeValueDraft, setAttributeValueDraft] = useState(props.attributeValue)
  const [pickerVisible, setPickerVisible] = useState(-1)
  const [feetItems, setFeetItems] = useState(map(range(3, 8), feet => ({ label: feet, value: feet })))
  const [inchesItems, setInchesItems] = useState(map(range(0, 13), inches => ({ label: inches, value: inches })))
  const height = props.attribute === 'height' && isNumber(props.attributeValue) ? props.attributeValue : 0
  const [feet, setFeet] = useState(floor(height / 12) || 5)
  const [inches, setInches] = useState(height % 12 || 0)
  const [radioButtons, setRadioButtons] = useState<RadioButtonProps[]>(concat(map(attributeOptions[props.attribute], option => ({
    id: option,
    value: option,
    label: has(attributeLabels, option) ? attributeLabels[option] : capitalize(option),
    containerStyle: {width: '100%'},
    labelStyle: {color: TEXT_WHITE, fontSize: 18},
    borderColor: TEXT_WHITE, color: SECONDARY_COLOR,
    selected: props.attributeValue === option
  })), {
      id: 'Prefer not to say',
      value: '',
      label: 'Prefer not to say',
      containerStyle: {width: '100%'},
      labelStyle: {color: TEXT_WHITE, fontSize: 18},
      borderColor: TEXT_WHITE, color: SECONDARY_COLOR,
      selected: !props.attributeValue
  }))

  const onPressRadioButton = (radioButtonsArray: RadioButtonProps[]) => {
    const selected = find(radioButtonsArray, {selected: true})
    setAttributeValueDraft(get(selected, 'value'))
    setRadioButtons(radioButtonsArray);
  }

  
  useImperativeHandle(
    ref,
    () => ({
      closeAllPickers() {
        closeAllPickers()
        }
     }),
   )

  useEffect(() => {
    (async () => {
      //setAbout(get(profile, 'about', []))
    })();
  }, [attributeValueDraft]);

  const closeAllPickers = () => {
    setPickerVisible(-1)
  }

  const getInput = () => {
    if(has(attributeOptions, props.attribute)) {
      return (
        <View style={{ flexDirection: 'row', height: 400, justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}>

          <RadioGroup 
            radioButtons={radioButtons} 
            onPress={onPressRadioButton} 
          />
        </View>
      )
    }
    else if(props.attribute === 'height') {
      return (
        <View style={{ flexDirection: 'row', height: 400, justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}>
           <DropDownPicker
            open={pickerVisible === 0}
            value={feet}
            zIndex={3000}
            style={{ borderColor: 'transparent', flexDirection: 'row', alignItems: 'center', width: 80 }}
            dropDownContainerStyle={[{ borderColor: 'transparent', paddingBottom: 10, maxWidth: 80 }]}
            containerStyle={[{ height: 40, margin: 10, maxWidth: 80, borderRadius: 4 }, Platform.OS === 'web' ? {backgroundColor: INPUT_BACKGROUND_COLOR} : {}]}
            labelStyle={{ fontSize: 18, textAlign: 'center', lineHeight: 40 }}
            listItemLabelStyle={{ fontSize: 18, textAlign: 'center', paddingVertical: 10 }}
            selectedItemLabelStyle={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold', color: SECONDARY_COLOR }}
            showTickIcon={false}
            showArrowIcon={true}
            arrowIconContainerStyle={{ backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center', paddingRight: 10}}
            placeholderStyle={{ display: 'none' }}
            items={feetItems}
            setItems={setFeetItems}
            setOpen={(open) => { open ? setPickerVisible(0) : closeAllPickers()}}
            setValue={setFeet}
            onChangeValue={(value) => {setAttributeValueDraft(value*12 + inches)}}
            theme="DARK"
            autoScroll={true}
            listMode="SCROLLVIEW"
            scrollViewProps={{
              nestedScrollEnabled: true,
            }}
          />
          <View style={{justifyContent: 'center', height: 40, marginVertical: 10}}>
            <Text style={{fontSize: 18, color: TEXT_WHITE, lineHeight: 50}}>{'ft'}</Text>
          </View>
          <DropDownPicker
            open={pickerVisible === 1}
            value={inches}
            style={{ borderColor: 'transparent', flexDirection: 'row', alignItems: 'center' }}
            dropDownContainerStyle={[{ borderColor: 'transparent', paddingBottom: 10, maxWidth: 100, elevation: 500 }]}
            containerStyle={[{ height: 40, margin: 10, maxWidth: 100, borderRadius: 4 }, Platform.OS === 'web' ? {backgroundColor: INPUT_BACKGROUND_COLOR} : {}]}
            labelStyle={{ fontSize: 18, textAlign: 'center', lineHeight: 40 }}
            listItemLabelStyle={{ fontSize: 18, textAlign: 'center', paddingVertical: 10 }}
            selectedItemLabelStyle={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold', color: SECONDARY_COLOR }}
            showTickIcon={false}
            showArrowIcon={true}
            arrowIconContainerStyle={{ backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center', paddingRight: 10}}
            placeholderStyle={{ display: 'none' }}
            items={inchesItems}
            setItems={setInchesItems}
            setOpen={(open) => { open ? setPickerVisible(1) : closeAllPickers()}}
            setValue={setInches}
            onChangeValue={(value) => {setAttributeValueDraft(feet*12 + value)}}
            theme="DARK"
            autoScroll={true}
            listMode="SCROLLVIEW"
            scrollViewProps={{
              nestedScrollEnabled: true,
            }}
          />
          <View style={{justifyContent: 'center', height: 40, marginVertical: 10}}>
            <Text style={{fontSize: 18, color: TEXT_WHITE, lineHeight: 50}}>{'in'}</Text>
          </View>
        </View>
      )
    }
    return (
      <View pointerEvents={'box-none'} style={[
        { width: '100%', height: 40, marginVertical: 10, zIndex: -1},
        styles.promptContainer,
      ]}> 
        <TextInput
          keyboardType={'default'}
          style={[styles.input]}
          placeholder={'None'}
          multiline={false}
          maxLength={50}
          textAlignVertical={'top'}
          placeholderTextColor={styles.smallText.color}
          defaultValue={props.attributeValue}
          onChangeText={(value) => setAttributeValueDraft(value)}
        />
      </View>
    )
  }

  return (
    <TouchableWithoutFeedback onPress={() => {closeAllPickers()}}>
      <View style={{width: '100%', maxWidth: vh(60), alignItems: 'center', paddingHorizontal: 20, marginVertical: 20}}>
        <View style={{width: '100%',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <TouchableOpacity onPress={props.onCancel} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
            <Text style={styles.saveButtonText}>{'Cancel'}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {props.onSave(props.attribute, attributeValueDraft)}}  hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
            <Text style={styles.saveButtonText}>{'Save'}</Text>
          </TouchableOpacity>
        </View>
        <View style={[{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  width: '100%', height: 100, paddingHorizontal: 10}, ]}>
            <Text style={styles.attributesTextStyle}>{startCase(has(attributeLabels, props.attribute) ? attributeLabels[props.attribute] : props.attribute)}</Text>
        </View>
        {getInput()}
      </View>
    </TouchableWithoutFeedback>
  )
})

export default AttributeEditor;

