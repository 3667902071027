import React, { useContext } from "react";
import {
  ScrollView,
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  Image
} from "react-native";
import config from "../config.js";
import * as AppStorage from '../context/storage'
import * as Notifications from 'expo-notifications';
import { useNavigation } from "@react-navigation/native";
import * as SecureStore from 'expo-secure-store';
import { first, get } from 'lodash';
import { Icon, PhotoPicker, ProfileItem, ProfileMain, ProfileEdit, ProfileAccount } from "../components";
import DEMO from "../assets/data/demo";
import styles, { INPUT_BACKGROUND_COLOR, WHITE } from "../assets/styles";
import { ProfileDataT } from "../types";
import * as RootNavigation from '../RootNavigation';
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack";
import { AppContext, profileAction } from '../context/app-context';

const Stack = createStackNavigator();

const Profile = () => {

  const forFade = ({ current }) => ({
    cardStyle: {
      opacity: current.progress,
    },
  });

  return (
    <View style={styles.containerHome}>
      <Stack.Navigator initialRouteName="profile_main" screenOptions={{ cardStyleInterpolator: forFade, cardStyle: { backgroundColor: 'transparent' } }} >
        <Stack.Screen name="profile_main" options={{ headerShown: false, animationEnabled: true }} component={ProfileMain} />
        <Stack.Screen name="profile_edit" options={{ headerShown: false, animationEnabled: true }} component={ProfileEdit} />
        <Stack.Screen name="profile_account" options={{ headerShown: false, animationEnabled: true }} component={ProfileAccount} />
      </Stack.Navigator>
    </View>
  );
};

export default Profile;
