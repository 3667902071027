import React, { useEffect, useState, useRef, useContext } from "react";
import config from "../config.js";
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DropDownPicker from 'react-native-dropdown-picker';
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import GradientButton from './GradientButton';
import * as AppStorage from '../context/storage'
import { useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, updateUserProfile, uploadPhoto, removePhoto } from "../context/app-context";
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR } from "../assets/styles";
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';
import { Asset } from 'expo-asset';
import { get, isEmpty, range, map, isNumber, forEach, noop } from 'lodash';
import { Feather, FontAwesome } from "@expo/vector-icons";
import { forModalPresentationIOS } from "@react-navigation/stack/lib/typescript/src/TransitionConfigs/CardStyleInterpolators";
import * as RootNavigation from '../RootNavigation';

const NamePicker = () => {
  const [isMonthPickerVisible, setIsMonthPickerVisible] = useState(false);
  const [isDayPickerVisible, setIsDayPickerVisible] = useState(false);
  const [isYearPickerVisible, setIsYearPickerVisible] = useState(false);
  const [birthdate_valid, set_birthdate_valid] = useState(true);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(1);
  const [year, setYear] = useState(1969);
  const [gender, setGender] = useState('male');
  const [isGenderPickerVisible, setIsGenderPickerVisible] = useState(false);
  const [first_name, set_first_name] = useState('');
  const [first_name_valid, set_first_name_valid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useContext(AppContext);

  const submitField = async () => {
    await setIsLoading(true)
    const result = await updateUserProfile(dispatch, { first_name: first_name })
    await setIsLoading(false);
    RootNavigation.navigate('birthdate')
  }

  const getLoadingIndicator = () => {
    return (
      <ActivityIndicator size={40} color={PRIMARY_COLOR} />
    )
  }

  const disabled = () => {
    return first_name.length < 2
  }

  const goBack = async () => {
    await AppStorage.deleteItemAsync('secure_token')
    RootNavigation.navigate('login')
  }

  return (
    <View style={[styles.container, styles.picker, styles.horizontal, styles.darkBackground]}>
      <Text style={styles.title}>What is your first name?</Text>
      <View style={[
        styles.phoneContainer,
        styles.inputShadow,
        { width: 300 }
      ]}>
        <TextInput
          keyboardType={'default'}
          style={[styles.input, { flex: 9 }]}
          placeholder="First Name"
          placeholderTextColor={styles.smallText.color}
          onChangeText={set_first_name}
          defaultValue={first_name}
        />
      </View>
      <View style={[styles.smallTextContainer, { zIndex: -1 }]}>
        <GradientButton
          style={{ marginVertical: 8, zIndex: 0 }}
          textStyle={{ fontSize: 20, color: TEXT_WHITE }}
          gradientBegin={PRIMARY_COLOR}
          gradientEnd={SECONDARY_COLOR}
          disabledGradientBegin={PRIMARY_COLOR_DISABLED}
          disabledGradientEnd={PRIMARY_COLOR_DISABLED}
          gradientDirection="diagonal"
          height={60}
          width={300}
          radius={15}
          impact
          impactStyle='Light'
          disabled={isLoading || disabled()}
          onPressAction={() => submitField()}
        >
          {isLoading ? getLoadingIndicator() : <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center' }}>{'Continue'}</Text>}
        </GradientButton>
        <TouchableOpacity
          style={{ marginVertical: 8, zIndex: 0 }}
          disabled={isLoading}
          onPress={() => goBack()}
        >
          {isLoading ? getLoadingIndicator() : <Text style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center' }}>{'Go Back'}</Text>}
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default NamePicker;
