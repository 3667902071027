import React, { useState, useEffect, useContext } from "react";
import { DataT, ProfileT } from "../types";
import { View, ImageBackground } from "react-native";
import CardStack, { Card } from "react-native-card-stack-swiper";
import { City, Filters, CardItem, PhotoSlider } from "../components";
import styles, { DIMENSION_WIDTH, PRIMARY_COLOR } from "../assets/styles";
import { AppContextProvider, AppContext, checkLogin, fetchUserProfile, fetchProfiles, fetchMatches, syncMyProfileData } from "../context/app-context";
import DEMO from "../assets/data/demo";

const Home = () => {
  const [state, dispatch] = useContext(AppContext);
  useEffect(() => {
    (async () => {

    })();
  }, []);
  return (
    <View style={styles.containerHome}>
      <PhotoSlider />
    </View>
  );
};

export default Home;
