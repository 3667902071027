import React from "react";
import { Text, View } from "react-native";
import Icon from "./Icon";
import styles, { WHITE, PRIMARY_COLOR, TEXT_WHITE_60, SECONDARY_COLOR } from "../assets/styles";
import { TabBarIconT } from "../types";

const TabBarIcon = ({ focused, iconName, text }: TabBarIconT) => {
  const iconFocused = focused ? SECONDARY_COLOR : TEXT_WHITE_60;

  return (
    <View style={styles.iconMenu}>
      <Icon name={iconName} size={30} color={iconFocused} style={styles.icon} />
      {/*<Text style={[styles.tabButtonText, { color: iconFocused }]}>{text}</Text>*/}
    </View>
  );
};

export default TabBarIcon;
