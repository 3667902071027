import React, {memo, Component} from "react";
import { Text, View, Platform, Image } from "react-native";
import config from "../config.js"
import Icon from "./Icon";
import styles, { WHITE, PRIMARY_COLOR, TEXT_WHITE_60, SECONDARY_COLOR } from "../assets/styles";
import { TabBarIconT } from "../types";
import { get, range, map, noop, floor, round, clamp, concat, find, reverse, cloneDeep, replace } from 'lodash'

class ChatBubble extends Component /* = ({ message, profile }) =>  */{
 
  shouldComponentUpdate(newProps) {
    return this.props.text !== newProps.text;
  }

  render() {
    return (
      <View style={[{ scaleY: -1, flexDirection: 'row', alignItems: 'center', justifyContent: this.props?.right ? 'flex-end' : 'flex-start'}]}>
        {this.props?.image && <Image
          source={typeof this.props?.image == "string" ? {uri: `${config.IMAGES_URL}/${this.props?.image}`}: this.props?.image}
          style={{borderRadius: 40, width: 40, height: 40, resizeMode: 'cover', marginHorizontal: 5}}
        />}
        <View style={[{ borderRadius: 8, flexShrink: 1, maxWidth: '60%', marginHorizontal: 10, marginVertical: 5, backgroundColor: 'rgba(54, 53, 84, 1)',
        alignItems: 'center', justifyContent: 'center'}]}>  
            <Text style={[styles.messageStyleSmall, {margin: 10, paddingHorizontal: 10, textAlign: 'center'}, styles.pcWidth100]}>{this.props?.text}</Text>
        </View>
      </View>
      
  );
}}

export default ChatBubble
