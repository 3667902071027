import React, { useEffect, useState, useRef, useContext } from "react";
import * as FileSystem from 'expo-file-system';
import config from "../config.js";
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform } from "react-native";
import * as ImagePicker from 'expo-image-picker';
import GradientButton from './GradientButton';
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import { AppContext, uploadPhoto, removePhoto, updateUserProfile, fetchUserProfile, fetchProfiles, fetchMatches, syncMyProfileData } from "../context/app-context";
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, WHITE } from "../assets/styles";
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';
import { Asset } from 'expo-asset';
import { get, range, map, noop, assign } from 'lodash';
import { Entypo, Feather, MaterialIcons } from "@expo/vector-icons";
import * as RootNavigation from '../RootNavigation'
import ConfirmationModal from "../modals/ConfirmationModal"

const PhotoPicker = ({ showContinue = true }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [loading, setLoading] = useState([]);

  /* const checkFileSize = async (fileURI: any) => {
    const fileSizeInBytes = await FileSystem.getInfoAsync(fileURI)
    return fileSizeInBytes
  } */

  const disabled = () => {
    return getNumPhotos() < 1
  }

  const submitField = async () => {
    await setIsLoading(true)
    console.log('submitting photo field')
    const result = await updateUserProfile(dispatch, { profile_complete: true })
    if (Platform.OS === 'web') {
      console.log('rdt!')
      try {
        fbq('trackCustom', 'SignUp')
      } catch (err) {
        console.error(err)
      }
      try {
        rdt('track', 'SignUp', {
          "currency": "USD",
          "transactionId": result?.id,
          "value": 1,
          "products": [
              {
                  "id": "singlesward.app",
                  "name": "singlesward.app",
                  "category": "singlesward.app"
              },
          // additional products can be added here
          ]
        })
      } catch (err) {
        console.error(err)
      }
      try {
        gtag("event", "SignUp", {method: "Google"})
      } catch (err) {
        console.error(err)
      }
      
      
      
    }
    
    await syncMyProfileData(dispatch)
    await fetchProfiles(dispatch)
    await fetchMatches(dispatch)
    await dispatch({
      type: "SET_PROFILE_INDEX",
      payload: 0
    })
    await setIsLoading(false);

    RootNavigation.navigate('home', { screen: 'profile', params: { screen: 'profile_edit' }})
  }

  const sizeImage = async (image: any) => {
    const maxWidth = 1280
    const ratio = image.height / image.width
    const height = maxWidth * ratio
    console.log('trying to size!')
    const manipResult = await manipulateAsync(
      image.localUri || image.uri,
      [
        { resize: { width: maxWidth, height: height } },
      ],
      {base64: true, compress: 0.5 }
    );
    console.log('sized!', manipResult)
    return manipResult
  };

  const getLoadingIndicator = () => {
    return (
      <ActivityIndicator size={40} color={PRIMARY_COLOR} />
    )
  }

  const pickImage = async (index) => {
    if (Platform.OS !== 'web') {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== 'granted') {
        console.log('Permission to access media was denied');
      return;
      }
    }
    
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      exif: false,
      aspect: [9, 16],
      quality: 0.5,
    })
    setLoading(assign([], loading, {[index] : true}))
    if (result.cancelled) {
      setLoading(assign([], loading, {[index] : false}))
      console.log('cancel!')
      return
    }
    console.log('image pick: ', result)
    const sized = await sizeImage(result)
    console.log('sized: ', sized)
    const newSize = sized.base64.length
    var base64bytes = sized.base64.split("base64,")[1] || sized.base64
    const resp = await uploadPhoto(dispatch, base64bytes)
    setLoading(assign([], loading, {[index] : false}))
  }

  const deletePhoto = (dispatch: any, image: any) => {
    if (getNumPhotos() > 1) {
      removePhoto(dispatch, image)
    }
  }

  const getNumPhotos = () => {
    return get(state, `user_profile.photos.length`, 0)
  }

  const showImagePreview = (image) => {
    if (!image) return
    
    ConfirmationModal.showContext(
      <View style={[{ width: vw(80), height: vh(60), maxWidth: vh(60), alignItems: 'center', justifyContent: 'flex-start', borderRadius: 20}]}>
        <Image
          source={typeof image == "string" ? { uri: `${config.IMAGES_URL}/${image}` } : image}
          style={{ position: 'absolute', width: '100%', height: '100%', resizeMode: 'cover', borderRadius: 20 }}
        />
      </View>
    )
  }

  const photoPlaceholder = (index: number) => {
    const image = get(state, `user_profile.photos[${index}]`)
    
    return (
      <TouchableWithoutFeedback key={index} onPress={()=>showImagePreview(image)}>
        <View  style={[{ justifyContent: 'center', alignItems: 'center', height: vh(15), width: vh(10), backgroundColor: INPUT_BACKGROUND_COLOR, margin: 10, flexBasis: '25%', flexGrow: 1, borderRadius: 8 }, 
        (index === 0 || index === 3) && {marginLeft: 0},
        (index === 2 || index === 5) && {marginRight: 0}
        ]}> 
          {
            loading[index] ? getLoadingIndicator() : <TouchableOpacity onPress={()=>pickImage(index)}><Feather name="plus" size={50} color={TEXT_WHITE} /></TouchableOpacity>
          }
          {image && !loading[index] && <Image
            source={typeof image == "string" ? { uri: `${config.IMAGES_URL}/${image}` } : image}
            style={{ position: 'absolute', width: '100%', height: '100%', resizeMode: 'cover', borderRadius: 8 }}
          />}
          {image && !loading[index] && (get(state, `user_profile.photos.length`, 0) > 1) &&
            <TouchableOpacity onPress={() => deletePhoto(dispatch, image)} style={{ top: 0, left: 0, position: 'absolute', alignSelf: 'flex-start'  }}>
              <MaterialIcons style={styles.backIcon} name="delete-forever" size={30} color={WHITE} />
            </TouchableOpacity>
          }
          
        </View>
      </TouchableWithoutFeedback>
    )
  }

  return (
    <View pointerEvents={'box-none'} style={[styles.container, styles.horizontal, styles.darkBackground]}>
      <Text style={styles.saveButtonText}>My Photos</Text>
      <View pointerEvents={'box-none'} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
        {map(range(0, 6), (index) => photoPlaceholder(index))}
      </View>
      {showContinue && <View style={[styles.smallTextContainer, { zIndex: -1 }]}>
        <GradientButton
          style={{ marginVertical: 8, zIndex: 0 }}
          textStyle={{ fontSize: 20, color: TEXT_WHITE }}
          gradientBegin={PRIMARY_COLOR}
          gradientEnd={SECONDARY_COLOR}
          disabledGradientBegin={PRIMARY_COLOR_DISABLED}
          disabledGradientEnd={PRIMARY_COLOR_DISABLED}
          gradientDirection="diagonal"
          height={60}
          width={300}
          radius={15}
          impact
          impactStyle='Light'
          disabled={isLoading || disabled()}
          onPressAction={() => submitField()}
        >
          {isLoading ? getLoadingIndicator() : <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center' }}>{'Continue'}</Text>}
        </GradientButton>
        <TouchableOpacity
          style={{ marginVertical: 8, zIndex: 0 }}
          disabled={isLoading}
          onPress={() => goBack()}
        >
          {isLoading ? getLoadingIndicator() : <Text style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center' }}>{'Go Back'}</Text>}
        </TouchableOpacity>
      </View>}
    </View>
  )
}

export default PhotoPicker;
