export const prompts = [
  'I Am Looking For',
  'The Church Is Important To Me Because',
  `When I Grow Up...`,
  `You'll Like Me If`,
  'A Funny Story From My Mission',
  'The Key To Winning Me Over',
  'My Favorite Place to Eat',
  'The Weirdest Thing About Me',
  `My Mom Thinks I'm Cool Because`,
  'My Superpower',
  'When the Zombie Apocalypse Hits I',
]