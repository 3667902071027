import React, { useEffect, useState, useRef, useContext } from "react"
import config from "../config.js"
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform, ScrollView, SafeAreaView, KeyboardAvoidingView, Keyboard } from "react-native"
import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, updateUserProfile, uploadPhoto, removePhoto, logout, deleteProfile } from "../context/app-context"
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, GRAY, WHITE, PRIMARY_BLUE, SECONDARY_BLUE, PRIMARY_ORANGE, SECONDARY_ORANGE, PRIMARY_TEAL, SECONDARY_TEAL, PRIMARY_RED, SECONDARY_RED, TEXT_WHITE_60 } from "../assets/styles";
import { get, isEmpty, range, map, isNumber, forEach, noop, assign, union, set, capitalize, floor } from 'lodash'
import { AntDesign, Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons"
import DropDownPicker from 'react-native-dropdown-picker'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import GradientButton from './GradientButton'
import PhotoPicker from './PhotoPicker'
import Icon from './Icon'
import PromptEditor from './PromptEditor'
import AttributeEditor from './AttributeEditor'
import * as RootNavigation from '../RootNavigation'
import { validatePathConfig } from "@react-navigation/native"
import ConfirmationModal from "../modals/ConfirmationModal"
import { prompts } from "../assets/prompts"

const ProfileAccount = () => {
  const [state, dispatch] = useContext(AppContext)
  const profile = state.user_profile
  const paused = get(profile, 'hidden', false)

  const goBack = async () => {
    RootNavigation.navigate('login')
  }

  const pauseAccount = async () => {
    const result = await updateUserProfile(dispatch, { hidden: !paused })
  }

  const confirmLogout = () => {
    ConfirmationModal.showContext(
        <View style={[styles.darkBackground, { width: 300, padding: 20, borderRadius: 8, alignItems: 'center', justifyContent: 'flex-start', marginBottom: 200}]}>
          <SafeAreaView pointerEvents={'box-none'} style={{width: '100%', alignItems: 'center'}}>
            <Text style={[styles.reportStyle, { fontSize: 20, color: TEXT_WHITE_60, textAlign: 'center' }]}>{'Are you sure you want to logout?'}</Text>
            <View style={{width: '80%',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20}}>
              <TouchableOpacity onPress={() => hideModal()} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
                <Text style={styles.saveButtonText}>{'CANCEL'}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {logout(dispatch);hideModal()}} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
                <Text style={styles.saveButtonText}>{'LOGOUT'}</Text>
              </TouchableOpacity>
            </View>
          </SafeAreaView>
        </View>
    )
  }

  const confirmPauseAccount = () => {
    ConfirmationModal.showContext(
        <View style={[styles.darkBackground, { width: 300, padding: 20, borderRadius: 8, alignItems: 'center', justifyContent: 'flex-start', marginBottom: 200}]}>
          <SafeAreaView pointerEvents={'box-none'} style={{width: '100%', alignItems: 'center'}}>
            <Text style={[styles.reportStyle, { fontSize: 20, color: TEXT_WHITE_60, textAlign: 'center' }]}>{paused ? 'Are you sure you want to unpause your account?' : `Are you sure you want to pause your account?  You will still be able to chat with your matches, but will be hidden from other users.`}</Text>
            <View style={{width: '80%',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20}}>
              <TouchableOpacity onPress={() => hideModal()} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
                <Text style={styles.saveButtonText}>{'CANCEL'}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {pauseAccount();hideModal()}} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
                <Text style={styles.saveButtonText}>{paused ? 'UNPAUSE' : 'PAUSE'}</Text>
              </TouchableOpacity>
            </View>
          </SafeAreaView>
        </View>
    )
  }

  const confirmDeleteProfile = () => {
    ConfirmationModal.showContext(
        <View style={[styles.darkBackground, { width: 300, padding: 20, borderRadius: 8, alignItems: 'center', justifyContent: 'flex-start', marginBottom: 200}]}>
          <SafeAreaView pointerEvents={'box-none'} style={{width: '100%', alignItems: 'center'}}>
            <Text style={[styles.reportStyle, { fontSize: 20, color: TEXT_WHITE_60, textAlign: 'center' }]}>{`Are you sure you want to permanently delete your account?`}</Text>
            <View style={{width: '80%',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20}}>
              <TouchableOpacity onPress={() => hideModal()} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
                <Text style={styles.saveButtonText}>{'CANCEL'}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {deleteProfile(dispatch);hideModal()}} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
                <Text style={styles.saveButtonText}>{'DELETE'}</Text>
              </TouchableOpacity>
            </View>
          </SafeAreaView>
        </View>
    )
  }

  const hideModal = () => {
    ConfirmationModal.hideContext()
  }


  return (
    <TouchableWithoutFeedback>
      <SafeAreaView pointerEvents={'box-none'} style={[styles.container, styles.horizontal, styles.darkBackground]}>
        <View style={[styles.bg, styles.containerHome, styles.horizontal, {flex: 1, paddingHorizontal: 20, marginTop: 20}]}> 
          <KeyboardAwareScrollView style={[styles.containerProfile, {marginTop: 40}]} showsVerticalScrollIndicator={true} keyboardOpeningTime={0} enableResetScrollToCoords={false}>
          <View style={{flex: 1, width: '100%'}}>
            <TouchableOpacity onPress={() => {confirmPauseAccount()}} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginVertical: 20, paddingHorizontal: 10}}>
              <GradientButton
                style={{ marginVertical: 0, marginHorizontal: 10, alignItems: 'center', justifyContent: 'center' }}
                gradientBegin={PRIMARY_BLUE}
                gradientEnd={SECONDARY_BLUE}
                gradientDirection="diagonal"
                height={44}
                width={44}
                radius={22}
                impact
                impactStyle='Light'
                onPressAction={() => confirmPauseAccount()}
              ><Ionicons name="pause" size={24} color={TEXT_WHITE} style={{lineHeight: 44}}></Ionicons ></GradientButton>
              <View style={{justifyContent: 'center', flex: 1}}><Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, fontWeight: '500' }}>{`  ${paused ? 'Unpause' : 'Pause'} Account`}</Text></View>
              <Icon
                name="chevron-forward"
                size={30}
                color={TEXT_WHITE}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {confirmLogout()}} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginVertical: 20, paddingHorizontal: 10}}>
              <GradientButton
                style={{ marginVertical: 0, marginHorizontal: 10, alignItems: 'center', justifyContent: 'center' }}
                gradientBegin={PRIMARY_COLOR_DISABLED}
                gradientEnd={SECONDARY_BLUE}
                gradientDirection="diagonal"
                height={44}
                width={44}
                radius={22}
                impact
                impactStyle='Light'
                onPressAction={() => {confirmLogout()}}
              ><MaterialIcons name="logout" size={24} color={TEXT_WHITE} style={{lineHeight: 44}}></MaterialIcons></GradientButton>
              <View style={{justifyContent: 'center', flex: 1}}><Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, fontWeight: '500' }}>{'  Logout'}</Text></View>
              <Icon
                name="chevron-forward"
                size={30}
                color={TEXT_WHITE}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {confirmDeleteProfile()}} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginVertical: 20, paddingHorizontal: 10}}>
              <GradientButton
                style={{ marginVertical: 0, marginHorizontal: 10, alignItems: 'center', justifyContent: 'center' }}
                gradientBegin={PRIMARY_RED}
                gradientEnd={SECONDARY_RED}
                gradientDirection="diagonal"
                height={44}
                width={44}
                radius={22}
                impact
                impactStyle='Light'
                onPressAction={() => {confirmDeleteProfile()}}
              ><MaterialIcons name="delete-forever" size={24} color={TEXT_WHITE} style={{lineHeight: 44}}></MaterialIcons></GradientButton>
              <View style={{justifyContent: 'center', flex: 1}}><Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, fontWeight: '500' }}>{'  Delete Account'}</Text></View>
              <Icon
                name="chevron-forward"
                size={30}
                color={TEXT_WHITE}
              />
            </TouchableOpacity>
          </View>
          </KeyboardAwareScrollView>
        </View>
        <TouchableOpacity style={[{position: 'absolute', justifyContent: 'center', alignItems: 'center', padding: 10}, styles.topIconLeft]} onPress={() => { RootNavigation.navigate('profile_main')  }}>
          <Icon
            name="chevron-back"
            size={40}
            color={WHITE}
          />
        </TouchableOpacity>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  )
}

export default ProfileAccount;

