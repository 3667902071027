import React, { useEffect, useState, useRef, useContext, useImperativeHandle } from "react"
import config from "../config.js"
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform, ScrollView, SafeAreaView, KeyboardAvoidingView, Keyboard } from "react-native"
import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, updateUserProfile, uploadPhoto, removePhoto } from "../context/app-context"
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, WHITE, TEXT_WHITE_60, TEXT_WHITE_40 } from "../assets/styles"
import { get, isEmpty, range, map, isNumber, forEach, noop, assign, union, set } from 'lodash'
import { AntDesign, Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import DropDownPicker from 'react-native-dropdown-picker'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import PhotoPicker from './PhotoPicker'
import Icon from './Icon'
import * as RootNavigation from '../RootNavigation'
import { validatePathConfig } from "@react-navigation/native"
import ConfirmationModal from "../modals/ConfirmationModal"
import { prompts } from "../assets/prompts"

const PromptEditor = React.forwardRef((props, ref) => { //({prompt, promptAnswer, onSave, onCancel, pressableFunc}) => {
  const [pickerVisible, setPickerVisible] = useState(false)
  const [items, setItems] = useState(map(prompts, prompt => ({ label: prompt, value: prompt })));
  const [promptDraft, setPromptDraft] = useState(props.prompt)
  const [promptAnswerDraft, setPromptAnswerDraft] = useState(props.promptAnswer)

  console.log('prompt', promptDraft)

  useImperativeHandle(
    ref,
    () => ({
      closeAllPickers() {
        setPickerVisible(false)
        }
     }),
 )

  useEffect(() => {
    (async () => {
      //setAbout(get(profile, 'about', []))
    })();
  }, [promptDraft, promptAnswerDraft]);

  const closeAllPickers = () => {
    setPickerVisible(false)
  }

  return (
    <TouchableWithoutFeedback onPress={() => setPickerVisible(false)}>
      <View style={{width: '100%', maxWidth: vh(60), alignItems: 'center', paddingHorizontal: 20, marginVertical: 20}}>
        <View style={{width: '100%',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <TouchableOpacity onPress={props.onCancel} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
            <Text style={styles.saveButtonText}>{'Cancel'}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {props.onSave(promptDraft, promptAnswerDraft, props.index)}} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
            <Text style={styles.saveButtonText}>{'Save'}</Text>
          </TouchableOpacity>
        </View>
        <View style={[{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  width: '100%', height: 100, paddingHorizontal: 10}, ]}>
          <TextInput
            keyboardType={'default'}
            style={[styles.input, { flex: 9, flexGrow: 1, paddingHorizontal: 0}, /* Platform.OS === "android" ? {elevation: zIndex-2} : {zIndex: zIndex-2} */]}
            placeholder="Prompt"
            maxLength={100}
            placeholderTextColor={styles.smallText.color}
            value={promptDraft}
            onChangeText={(value) => setPromptDraft(value)}
          />
          <DropDownPicker
            open={pickerVisible}
            value={props.prompt}
            style={{ borderColor: 'transparent', backgroundColor: 'transparent', flex: 9 }}
            dropDownContainerStyle={[{ borderColor: 'transparent', width: vw(90), maxWidth: vh(50), right: -10, paddingBottom: 10 }]}
            containerStyle={[{ width: 44, alignItems: 'flex-end', justifyContent: 'center' }]}
            labelStyle={{ display: 'none' }}
            listItemLabelStyle={{ fontSize: 14, textAlign: 'center', paddingVertical: 10 }}
            selectedItemLabelStyle={{ fontSize: 14, textAlign: 'center', fontWeight: 'bold', color: SECONDARY_COLOR }}
            showTickIcon={false}
            showArrowIcon={true}
            arrowIconContainerStyle={{ backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center'}}
            placeholderStyle={{ display: 'none' }}
            items={items}
            setItems={setItems}
            setOpen={(open) => { setPickerVisible(open);Keyboard.dismiss() }}
            setValue={setPromptDraft}
            theme="DARK"
            autoScroll={true}
            listMode="SCROLLVIEW"
            scrollViewProps={{
              nestedScrollEnabled: true,
            }}
          />
        </View>

        <View pointerEvents={'box-none'} style={[
          { width: '100%', height: 200, marginVertical: 10, padding: 10, zIndex: -1},
          styles.promptContainer,
        ]}>  
          <TextInput
            keyboardType={'default'}
            style={[styles.input, { flex: 9, flexGrow: 1, height: 200}, Platform.OS === "android" ? {elevation: -4} : {zIndex: -4}]}
            placeholder={props.prompt}
            multiline={true}
            maxLength={500}
            textAlignVertical={'top'}
            numberOfLines={Platform.OS === 'web' ? 8: undefined}
            placeholderTextColor={styles.smallText.color}
            defaultValue={props.promptAnswer}
            onChangeText={(value) => setPromptAnswerDraft(value)}
          />
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
})

export default PromptEditor;

