import React, {memo, Component} from "react"
import { Text, View, Platform, TouchableWithoutFeedback, BackHandler } from "react-native"
import { BlurView } from 'expo-blur'
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'


export default class ConfirmationModal extends Component {
    public static Instance: ConfirmationModal;
    
    constructor (props) {
        super(props);
        ConfirmationModal.boundInstance(this);
        this.state = {
            showContext: false
        };
    }

    public render (): JSX.Element {
        return this.state.showContext ? <View style={{flex: 1,alignItems: 'center',justifyContent: 'center', position: 'absolute', width: '100%', height: '100%'}}>
            <TouchableWithoutFeedback onPress={() => {this.hideContext()}} style={{display:'none'}}>
                <BlurView intensity={90} tint="dark" style={{ position: 'absolute', zIndex: 0, width: vw(200), height: vh(200), top: vh(-100), left: vw(-100) }} />
            </TouchableWithoutFeedback>
            <View
              style={{
                flex: 1,
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center'}}>
            {this.state.context}
            </View>
        </View> : 
        <View></View>
    }

    public showContext (context: JSX.Element): void {
        BackHandler.addEventListener('hardwareBackPress', () => {
            if(this.state.showContext === false) {
                return false
            }
            this.setState({showContext: false})
            return true
        })
        this.setState({
            context,
            showContext: true
        });
    }
    public hideContext (): void {
        this.setState({
            showContext: false
        });
    }
    public static boundInstance (that: ConfirmationModal): void {
        ConfirmationModal.Instance = that;
    }
    public static showContext (context: JSX.Element): void {
        ConfirmationModal.Instance.showContext(context);
    }
    public static hideContext (): void {
        ConfirmationModal.Instance.hideContext();
    }
}