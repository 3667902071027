import React, { useEffect, useState, useRef, useContext } from "react";
import config from "../config.js";
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Image,
  FlatList, 
  Button,
  TouchableWithoutFeedback,
  Platform,
  SafeAreaView
} from "react-native";
import WebModal from "modal-enhanced-react-native-web";
import Modal from "react-native-modal";
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import { CardItem, Icon, NamePicker, PhotoSlider, ProfileView, TabBarLabel, GradientButton } from "../components";
import { first } from 'lodash';
import DEMO from "../assets/data/demo";
import { Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { AppContext, uploadPhoto, removePhoto, profileAction, incrementProfileIndex } from "../context/app-context";
import { LinearGradient } from 'expo-linear-gradient';
import styles, { GRAY, DARK_GRAY, DIMENSION_HEIGHT, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, WHITE, TEXT_WHITE, BACKGROUND_COLOR, SECONDARY_GREEN, PRIMARY_GREEN, PRIMARY_BLUE, SECONDARY_BLUE } from "../assets/styles";
import { createStackNavigator } from "@react-navigation/stack";
import { get, range, map, noop, floor, round, clamp, isEmpty, filter, isMatch } from 'lodash';
import { BlurView } from 'expo-blur';
import * as RootNavigation from '../RootNavigation';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import ProfileModal from "../modals/ProfileModal"
import ConfirmationModal from "../modals/ConfirmationModal"

const Tab = createMaterialTopTabNavigator()

const Matches = () => {
  const [state, dispatch] = useContext(AppContext)

  const unfilteredMatches = get(state, `matches`, [])
  const matches = filter(unfilteredMatches, {is_liked: true, likes_me: true})
  const likes = filter(unfilteredMatches, {is_liked: null, likes_me: true})


  const showProfileModal = (profile) => {
    ProfileModal.showContext(
      <View style={{width: vw(100), height: vh(100), maxWidth: vh(60)}}>
        <ProfileView profile={profile} width={vw(100)} height={vh(100)}>
          {getActions(profile)}
        </ProfileView>
        <TouchableOpacity style={[{position: 'absolute'}, styles.topIconLeft, {top: 50}]} onPress={() => {hideProfileModal()}} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
          <Icon
            name="chevron-back"
            size={40}
            color={WHITE}
          />
        </TouchableOpacity>
      </View>
    )
  }

  const hideProfileModal = () => {
    console.log('hide!!!!!!')
    ProfileModal.hideContext()
  }

  const getActions = (profile) => {
    const profileIsMatch = isMatch(profile, {is_liked: true, likes_me: true})
    const profileIsLike = isMatch(profile, {is_liked: null, likes_me: true})
    return (
    <View pointerEvents={'box-none'} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', zIndex: 2, width: '100%', height: '100%', paddingBottom: 20 }}>
      <GradientButton
        style={{ marginVertical: 0, marginHorizontal: 20 }}
        gradientBegin={GRAY}
        gradientEnd={DARK_GRAY}
        gradientDirection="diagonal"
        height={60}
        width={60}
        impact
        impactStyle='Light'
        onPressAction={() => doAction('dislike', profile)}
      ><Ionicons name="heart-dislike-sharp" size={40} color={TEXT_WHITE} /></GradientButton>
      {profileIsLike && <GradientButton
        style={{ marginVertical: 0, marginHorizontal: 20 }}
        gradientBegin={PRIMARY_COLOR}
        gradientEnd={SECONDARY_COLOR}
        gradientDirection="diagonal"
        height={60}
        width={60}
        impact
        impactStyle='Light'
        onPressAction={() => doAction('like', profile)}
      ><Ionicons name="heart-sharp" size={40} color={TEXT_WHITE} /></GradientButton>}
      {profileIsMatch && <GradientButton
        style={{ marginVertical: 0, marginHorizontal: 20 }}
        gradientBegin={PRIMARY_BLUE}
        gradientEnd={SECONDARY_BLUE}
        gradientDirection="diagonal"
        height={60}
        width={60}
        impact
        impactStyle='Light'
        onPressAction={() => {RootNavigation.navigate('messages', { screen: 'conversation', params: { id: profile?.id }});hideProfileModal()}}
      ><Ionicons name="chatbubble" size={40} color={TEXT_WHITE} /></GradientButton>}
    </View>
    )
  }
  

  const matchCard = (match) => {
    const image = get(match, `photos[0]`)
    return (
      <TouchableOpacity
        style={[{ width: 150, height: 180, flexDirection: 'column', alignItems: 'center', margin: 10, borderRadius: 8, overflow: 'hidden' }]}
        key={match?.id}
        onPress={() => { showProfileModal(match) }}
      >
        <Image
          source={typeof image == "string" ? { uri: `${config.IMAGES_URL}/${image}` } : image}
          style={{ borderRadius: 8, width: 150, height: 180, resizeMode: 'cover' }}
        />
        <View style={[{ borderRadius: 8, height: '100%', width: '100%', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-end', position: 'absolute' }]}>
          <LinearGradient pointerEvents={'none'} style={{ position: 'absolute', top: 0, height: '100%', width: '100%' }} colors={['rgba(54, 53, 84, 0)', 'rgba(54, 53, 84, 1)']} locations={[0.4, 0.99]} />
          <Text style={[styles.nameStyleSmall, { marginLeft: 10, marginBottom: 5, zIndex: 1, elevation: 1, color: TEXT_WHITE }]}>{match?.first_name}</Text>
        </View>
      </TouchableOpacity>
    )
  }

  const forFade = ({ current }) => ({
    cardStyle: {
      opacity: current.progress,
    },
  });

  const doAction = async (action: string, profile) => {
      await profileAction(dispatch, action, profile?.id)
      hideProfileModal()
      if (action === 'like') {
        RootNavigation.navigate('messages', { screen: 'conversation', params: { id: profile?.id }})
      }
  }


  const getProfileList = (listItems) => {
    return (
      <View style={[styles.container, styles.horizontal, styles.darkBackground]}>
        <FlatList
            numColumns={2}
            data={listItems}
            keyExtractor={(item, index) => (index.toString())}
            renderItem={({ item }) => (matchCard(item))}
          />
          {isEmpty(listItems) && 
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', zIndex: 4, position: 'absolute', width: '100%', height: '100%', paddingHorizontal: 50 }}>
            <Text style={[styles.attributesTextStyle, { textAlign: "center", marginVertical: 20 }]}>{`No likes quite yet, try editing your profile to catch someone's eye!`}</Text>
            <GradientButton
              style={{ marginVertical: 0, marginLeft: 0, justifyContent: 'center', alignItems: 'center', marginVertical: 20 }}
              gradientBegin={PRIMARY_COLOR}
              gradientEnd={SECONDARY_COLOR}
              gradientDirection="diagonal"
              height={60}
              width={300}
              impact
              impactStyle='Light'
              onPressAction={() => { RootNavigation.navigate('profile') }}
            >
              <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center', fontWeight: '500', lineHeight: 60 }}>{`Edit Profile`}</Text>
            </GradientButton>
          </View>}
        </View>
    )
  }

  return (
    <View pointerEvents={'box-none'} style={[styles.bg, styles.horizontal, styles.darkBackground]} >
      <SafeAreaView pointerEvents={'box-none'} style={[styles.container, styles.horizontal, styles.darkBackground]}>
        <Tab.Navigator style={{width: '100%'}}
          screenOptions={{
            tabBarIndicatorStyle: {
              backgroundColor: 'transparent'
            },
            tabBarStyle: {
              elevation: 0,
              shadowOpacity: 0,
              shadowColor: 'transparent',
              borderColor: 'transparent',
              borderWidth: 0,
              backgroundColor: 'transparent',
              borderTopColor: 'transparent',
            }}}
          >
          <Tab.Screen name="likes" options={{ tabBarLabel: ({ focused }) => (
              <TabBarLabel
                  focused={focused}
                  text={`Likes You ${likes.length > 0 ? '(' + likes.length + ')' : ''}`}
              />
              ) }} children={() => getProfileList(likes)}/>
          <Tab.Screen name="matches" options={{ tabBarLabel: ({ focused }) => (
              <TabBarLabel
                  focused={focused}
                  text={`Matches ${matches.length > 0 ? '(' + matches.length + ')' : ''}`}
              />
              ) }} children={() => getProfileList(matches)}/>
        </Tab.Navigator>
      </SafeAreaView>
    </View>
  )
}

export default Matches;
