import React, {memo, Component} from "react"
import { Text, View, Platform } from "react-native"
import ProfileModal from "./ProfileModal";

export default class ProfileModalContext extends Component {
    constructor (props) {
        super(props);
    }
    public render (): JSX.Element {
        return (
            <View style={{ flex: 1 }}>
                {this.props.children}
                <ProfileModal />
           </View>
        );
    }
}