import React, { useEffect, useState, useRef, useContext } from "react";
import config from "../config.js";
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DropDownPicker from 'react-native-dropdown-picker';
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import GradientButton from './GradientButton';
import { useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import * as AppStorage from '../context/storage'
import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, updateUserProfile, uploadPhoto, removePhoto } from "../context/app-context";
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR } from "../assets/styles";
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';
import { Asset } from 'expo-asset';
import { get, isEmpty, range, map, isNumber, forEach, noop } from 'lodash';
import { Feather, FontAwesome } from "@expo/vector-icons";
import { forModalPresentationIOS } from "@react-navigation/stack/lib/typescript/src/TransitionConfigs/CardStyleInterpolators";
import * as RootNavigation from '../RootNavigation';

const GenderPicker = ({ onChangeValue = noop, children = null }) => {
  const [gender, setGender] = useState('male');
  const [isLoading, setIsLoading] = useState(false);
  const [isGenderPickerVisible, setIsGenderPickerVisible] = useState(false);
  const [state, dispatch] = useContext(AppContext);

  const closeAllPickers = () => {
    setIsGenderPickerVisible(false)
  }

  const submitField = async () => {
    await setIsLoading(true)
    const result = await updateUserProfile(dispatch, { gender: gender })
    await setIsLoading(false);
    RootNavigation.navigate('photos')
  }

  const getLoadingIndicator = () => {
    return (
      <ActivityIndicator size={40} color={PRIMARY_COLOR} />
    )
  }

  const disabled = () => {
    return !(gender === 'male' || gender === 'female')
  }

  const goBack = async () => {
    await AppStorage.deleteItemAsync('secure_token')
    RootNavigation.navigate('login')
  }


  return (
    <TouchableWithoutFeedback onPress={() => closeAllPickers()}>
      <View style={[styles.container, styles.horizontal, styles.darkBackground]}>
        <Text style={styles.title}>What is your gender?</Text>
        <View style={{ flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center', width: DIMENSION_WIDTH }}>
          <DropDownPicker
            open={isGenderPickerVisible}
            value={gender}
            zIndex={3000}
            style={{ borderColor: 'transparent' }}
            dropDownContainerStyle={{ borderColor: 'transparent' }}
            containerStyle={[{ height: 40, maxWidth: 140, }]}
            labelStyle={{ fontSize: 22, textAlign: 'center' }}
            listItemLabelStyle={{ fontSize: 22, textAlign: 'center', paddingVertical: 10 }}
            selectedItemLabelStyle={{ fontSize: 22, textAlign: 'center', fontWeight: 'bold', color: SECONDARY_COLOR }}
            showTickIcon={false}
            showArrowIcon={false}
            placeholderStyle={{ display: 'none' }}
            items={[{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }]}
            setOpen={(open) => { setIsGenderPickerVisible(open) }}
            setValue={setGender}
            theme="DARK"
            autoScroll={true}
            listMode="SCROLLVIEW"
            scrollViewProps={{
              nestedScrollEnabled: true,
            }}
          />
        </View>
        <View style={[styles.smallTextContainer, { zIndex: -1 }]}>
          <GradientButton
            style={{ marginVertical: 8, zIndex: 0 }}
            textStyle={{ fontSize: 20, color: TEXT_WHITE }}
            gradientBegin={PRIMARY_COLOR}
            gradientEnd={SECONDARY_COLOR}
            disabledGradientBegin={PRIMARY_COLOR_DISABLED}
            disabledGradientEnd={PRIMARY_COLOR_DISABLED}
            gradientDirection="diagonal"
            height={60}
            width={300}
            radius={15}
            impact
            impactStyle='Light'
            disabled={isLoading || disabled()}
            onPressAction={() => submitField()}
          >
            {isLoading ? getLoadingIndicator() : <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center' }}>{'Continue'}</Text>}
          </GradientButton>
          <TouchableOpacity
            style={{ marginVertical: 8, zIndex: 0 }}
            disabled={isLoading}
            onPress={() => goBack()}
          >
            {isLoading ? getLoadingIndicator() : <Text style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center' }}>{'Go Back'}</Text>}
          </TouchableOpacity>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default GenderPicker;
