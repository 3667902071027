import { ActivityIndicator, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as SecureStore from 'expo-secure-store'

export const setItemAsync = async (key: string, value: string, options?: SecureStore.SecureStoreOptions): Promise<void> => {
    if(Platform.OS === 'web') {
      return await AsyncStorage.setItem(key, value)
    }
    return await SecureStore.setItemAsync(key, value, options)
  }
  
  export const getItemAsync = async (key: string, options?:  SecureStore.SecureStoreOptions): Promise<string | null> => {
    if(Platform.OS === 'web') {
      return await AsyncStorage.getItem(key)
    }
    return await SecureStore.getItemAsync(key, options)
  }
  
  export const deleteItemAsync = async (key: string, options?:  SecureStore.SecureStoreOptions): Promise<void> => {
    if(Platform.OS === 'web') {
      return await AsyncStorage.removeItem(key)
    }
    return await SecureStore.deleteItemAsync(key, options)
  }