const config = {
    API_URL: 'https://api.singlesward.app',
    IMAGES_URL: 'https://images.singlesward.app',
    FB_APP_ID: '587084592397778',
    VAPID_PUBLIC_KEY: 'BHoYbXhoWhmbdTGxlGk7fhvj-Ord9iY1ZObDiE5OAHWawBqGKhfT5c8Ja8K8bObI1nAIpipAFvBEOgst96NrKx8',
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyBt9jW7HeK15x2ozxaYa0UsyD3aJLpNAE4",
        authDomain: "singles-ward.firebaseapp.com",
        projectId: "singles-ward",
        storageBucket: "singles-ward.appspot.com",
        messagingSenderId: "341871825412",
        appId: "1:341871825412:web:f53112f4d70c3324d7db51",
        measurementId: "G-B5K76YNH6S"
      }
};


export default config;