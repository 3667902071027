import React, {useState, useEffect, useReducer, createContext} from "react"
import { ActivityIndicator, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AppStorage from '../context/storage'
import { NavigationActions } from '@react-navigation/native';
import * as SecureStore from 'expo-secure-store'
import {queryProfiles, queryMatches, doProfileAction, doMessageProfile, fetchProfile, patchProfile, doUploadPhoto, doRemovePhoto, updateProfileLocationToken, doProfileRewind, doDeleteProfile} from './actions/profiles-api'
import jwt_decode from "jwt-decode";
import { get, concat, isEmpty, isNumber, uniqBy, map, findIndex, slice, without, reject } from 'lodash';
import { State } from "react-native-gesture-handler";
import Toast from 'react-native-toast-message'
import * as RootNavigation from '../RootNavigation'

export const AppContext = createContext();

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const initialState = {
    profiles: [],
    matches: [],
    user_profile: {},
    profile: {},
    nextProfile: {},
    profile_index: 0,
    logged_in: false,
    token: '',
    loading: false,
    error: null
}

export const checkLogin = async () => {
  //await SecureStore.setItemAsync('secure_token', `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJleHAiOjE2NjgyMDAwMDUsImlhdCI6MTYzNjY2NDAwNSwicGhvbmUiOiIrMTQzNTU5MDc2MTciLCJ1c2VySUQiOiJjNDQ4YTdmYmJhMzg0ZjkwYjJmNTY1OTE3MGNlOTVmYiJ9.uOKpGA6k-31AQ0P-82n-FAH3HjBfdZdKhOjT10Kt0Co`)
  let token = await AppStorage.getItemAsync('secure_token')
  //await sleep(1000)
  //token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJleHAiOjE2NjgyMDAwMDUsImlhdCI6MTYzNjY2NDAwNSwicGhvbmUiOiIrMTQzNTU5MDc2MTciLCJ1c2VySUQiOiJjNDQ4YTdmYmJhMzg0ZjkwYjJmNTY1OTE3MGNlOTVmYiJ9.uOKpGA6k-31AQ0P-82n-FAH3HjBfdZdKhOjT10Kt0Co`;
  if(!token) {
    return {
      logged_in: false
    }
  }
  try {
    const decoded = jwt_decode<any>(String(token));
    //check expired
    //setIsLoggedIn(true);
    
  } catch(error) {
    await AppStorage.deleteItemAsync('secure_token')
    //NavigationActions.init()
    return {
      logged_in: false
    }
  } finally {
    return {
      logged_in: true,
      token: token
    }
  }
}

export const syncMyProfileData = async (dispatch : any) => {
  const response = await updateProfileLocationToken()
  if(response.error) return
  if(response.profile) await AsyncStorage.setItem('user_profile', JSON.stringify(response.profile))
  await dispatch({
    type: "RECEIVE_USER_PROFILE",
    payload: response.profile
  })
}

export const fetchUserProfile = async (dispatch : any) => {
  const response = await fetchProfile()
  if(response.error) {
    console.log('toast!')
    Toast.show({
      type: 'error',
      text1: 'Error',
      text2: 'Failed to retrieve your user information'
    })
    await dispatch({
      type: "RECEIVE_USER_PROFILE_ERROR",
      payload: true
    })
    return
  } 
  if(response.profile) await AsyncStorage.setItem('user_profile', JSON.stringify(response.profile))
  await dispatch({
    type: "RECEIVE_USER_PROFILE",
    payload: response.profile
  })
  return response
}

export const updateUserProfile = async (dispatch : any, profile : any) => {
  const response = await patchProfile(profile)
  if(response.error) return
  if(response.profile) await AsyncStorage.setItem('user_profile', JSON.stringify(response.profile))
  await dispatch({
    type: "RECEIVE_USER_PROFILE",
    payload: response.profile
  })

  return response.profile
}

export const deleteProfile = async(dispatch : any) => {
  await doDeleteProfile()
  logout(dispatch)
}

export const logout = async (dispatch : any) => {
  await AppStorage.deleteItemAsync('secure_token')
  RootNavigation.navigate('login')
  await dispatch({
    type: "LOGOUT"
  })
}

export const fetchProfiles = async (dispatch : any, clear=false) => {
  const response = await queryProfiles()
  await dispatch({
    type: clear ? "REPLACE_PROFILES" : "RECEIVE_PROFILES",
    payload: response.profiles
  })
}

export const fetchMatches = async (dispatch : any) => {
  const response = await queryMatches()
  await dispatch({
    type: "RECEIVE_MATCHES",
    payload: response.profiles
  })
}

export const profileAction = async (dispatch: any, action : string, otherProfileID : string, details? : any) => {
  await doProfileAction(action, otherProfileID, details)
  if(action === 'block' || action === 'report') {
    await dispatch({
      type: "REMOVE_PROFILE",
      payload: otherProfileID
    })
  }

  const response = await queryMatches()
  await dispatch({
    type: "RECEIVE_MATCHES",
    payload: response.profiles
  })
}

export const profileRewind = async (dispatch: any) => {
  await doProfileRewind()
  await fetchProfiles(dispatch, true)
  await dispatch({
    type: "SET_PROFILE_INDEX",
    payload: 0
  })
}

export const incrementProfileIndex = async (dispatch: any) => {
  await dispatch({
    type: "INCREMENT_PROFILE_INDEX"
  })
}

export const messageProfile = async (dispatch: any, message : string, otherProfileID : string) => {
  const response = await doMessageProfile(message, otherProfileID)
  await dispatch({
    type: "RECEIVE_UPDATED_MATCH",
    payload: response.profile
  })
}

export const uploadPhoto = async (dispatch: any, photo : string) => {
  const response = await doUploadPhoto(photo)
  if(response.error) return
  if(response.profile) await AsyncStorage.setItem('user_profile', JSON.stringify(response.profile))
  await dispatch({
    type: "RECEIVE_USER_PROFILE",
    payload: response.profile
  })
  return response
}

export const removePhoto = async (dispatch: any, photo : string) => {
  const response = await doRemovePhoto(photo)
  if(response.error) return
  if(response.profile) await AsyncStorage.setItem('user_profile', JSON.stringify(response.profile))
  await dispatch({
    type: "RECEIVE_USER_PROFILE",
    payload: response.profile
  })
}

const reducer = (state, action) => {
switch (action.type) {
  case "RECEIVE_USER_PROFILE":
    return {
      ...state,
      user_profile: action.payload,
      user_profile_error: false
    };
  case "RECEIVE_USER_PROFILE_ERROR":
    return {
      ...state,
      user_profile_error: action.payload
    };  
  case "RECEIVE_PROFILES":
    return {
      ...state,
      profiles: uniqBy(concat(state.profiles, action.payload), 'id')
    };
  case "REPLACE_PROFILES":
    return {
      ...state,
      profiles: action.payload
    }
  case "RECEIVE_MATCHES":
    return {
      ...state,
      matches: action.payload
    }
  case "RECEIVE_UPDATED_MATCH":
      return {
        ...state,
        matches: map(state.matches, (match) => (match?.id === action?.payload?.id ? action.payload : match))
      }  
  case "RECEIVE_LOGIN":
    return {
      ...state,
      logged_in: action.payload.logged_in,
      token: action.payload.token
    };
  case "SET_PROFILE_INDEX":
    return {
      ...state,
      profile_index: action.payload,
      profile: state.profiles?.[action.payload],
      nextProfile: state.profiles?.[action.payload+1]
    }
  case "INCREMENT_PROFILE_INDEX":
    let newProfiles = state.profiles
    if(state.profile_index % 18 == 0) {
      newProfiles = map(state.profiles, (profile, index) => (index < state.profile_index - 1 ? {id: profile.id} : profile))
    }
    const newIndex = state.profile_index+1
    return {
      ...state,
      profile_index: newIndex,
      profile: state.profiles[newIndex],
      nextProfile: state.profiles[newIndex+1],
      profiles: newProfiles
    }
  case "REMOVE_PROFILE":
    return {
      ...state,
      matches: reject(state.matches, {id: action.payload})
    }
  case "LOADING":
    return {
      ...state,
      loading: action.payload
    };
  case "LOGOUT":
    return initialState
  default:
    return state
}
};

export const AppContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
  
    return (
      <AppContext.Provider value={[state, dispatch]}>
        {props.children}
      </AppContext.Provider>
    );
  };