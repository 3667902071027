import React, { useEffect, useState, useRef, useContext, useImperativeHandle } from "react"
import config from "../config.js"
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform, ScrollView, SafeAreaView, KeyboardAvoidingView, Keyboard } from "react-native"
import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, updateUserProfile, uploadPhoto, removePhoto } from "../context/app-context"
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, WHITE, TEXT_WHITE_60, TEXT_WHITE_40 } from "../assets/styles"
import { get, isEmpty, range, map, isNumber, forEach, noop, assign, union, set } from 'lodash'
import { AntDesign, Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import DropDownPicker from 'react-native-dropdown-picker'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import PhotoPicker from './PhotoPicker'
import Icon from './Icon'
import * as RootNavigation from '../RootNavigation'
import { validatePathConfig } from "@react-navigation/native"
import ConfirmationModal from "../modals/ConfirmationModal"
import { reports } from "../assets/reports"

const ReportView = React.forwardRef((props, ref) => { //({prompt, promptAnswer, onSave, onCancel, pressableFunc}) => {
  const [pickerVisible, setPickerVisible] = useState(false)
  const [items, setItems] = useState(map(reports, report => ({ label: report, value: report })));
  const [report, setReport] = useState('')
  const [reportDetails, setReportDetails] = useState('')

  useImperativeHandle(
    ref,
    () => ({
      closeAllPickers() {
        setPickerVisible(false)
        }
     }),
 )

  useEffect(() => {
    (async () => {
      //setAbout(get(profile, 'about', []))
    })();
  }, [report, reportDetails]);

  const closeAllPickers = () => {
    setPickerVisible(false)
  }

  return (
    <TouchableWithoutFeedback onPress={() => {setPickerVisible(false);Platform.OS !== 'web' ? Keyboard.dismiss() : noop}}>
      <View style={{width: '100%', maxWidth: vh(60), alignItems: 'center', paddingHorizontal: 20, marginVertical: 20}}>
        <Text style={[styles.reportStyle, { fontSize: 20, color: TEXT_WHITE_60, textAlign: 'center' }]}>{props.message}</Text>
        <View style={[{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  width: '100%', height: 200, paddingHorizontal: 10, marginTop: -50}, ]}>
          <DropDownPicker
            open={pickerVisible}
            value={report}
            style={{ borderColor: 'transparent', flexDirection: 'row', alignItems: 'center', backgroundColor: INPUT_BACKGROUND_COLOR, borderRadius: 4 }}
            dropDownContainerStyle={[{ borderColor: 'transparent', paddingBottom: 10, width: 220, elevation: 500 }]}
            containerStyle={[{ height: 40, margin: 10, width: 220, borderRadius: 4 }, Platform.OS === 'web' ? {backgroundColor: INPUT_BACKGROUND_COLOR} : {}]}
            labelStyle={{  paddingLeft: 10, fontSize: 14, textAlign: 'left', lineHeight: 40 }}
            listItemLabelStyle={{ fontSize: 14, textAlign: 'center', paddingVertical: 10 }}
            selectedItemLabelStyle={{ fontSize: 14, textAlign: 'center', fontWeight: 'bold', color: SECONDARY_COLOR }}
            showTickIcon={false}
            showArrowIcon={true}
            arrowIconContainerStyle={{ backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center', paddingRight: 10}}
            placeholderStyle={{ paddingLeft: 10, fontSize: 14, textAlign: 'left', lineHeight: 40 }}
            items={items}
            setItems={setItems}
            setOpen={(open) => { setPickerVisible(open);Keyboard.dismiss() }}
            setValue={setReport}
            theme="DARK"
            autoScroll={true}
            listMode="SCROLLVIEW"
            scrollViewProps={{
              nestedScrollEnabled: true,
            }}
          />
        </View>

        <View pointerEvents={'box-none'} style={[
          { width: '100%', height: 40, marginTop: -50, marginBottom: 80},
          Platform.OS === "android" ? {elevation: -1} : {zIndex: pickerVisible ? -1: 0},
          styles.promptContainer,
        ]}>  
          <TextInput
            keyboardType={'default'}
            style={[styles.input, { flex: 9, flexGrow: 1, height: 40}, Platform.OS === "android" ? {elevation: -4} : {zIndex: -4}]}
            placeholder={'Tell us more'}
            maxLength={200}
            textAlignVertical={'top'}
            placeholderTextColor={styles.smallText.color}
            onChangeText={(value) => setReportDetails(value)}
          />
        </View>
        
        <View style={[{width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20}, Platform.OS === "android" ? {elevation: -4} : {zIndex: -4}]}>
          <TouchableOpacity onPress={props.onCancel} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
              <Text style={styles.saveButtonText}>{'CANCEL'}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {props.onConfirm(report, reportDetails)}} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
              <Text style={styles.saveButtonText}>{'REPORT'}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
})

export default ReportView;

